
<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                Customer Form
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-card class="my-12">
                        <v-toolbar class="darken-1" color="blue" dark fixed>
                            <v-toolbar-title>
                                Find Previous ID
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                            :items="['Passport','Citizen']"
                                            label="Type"
                                            v-model="trackType"
                                      >
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            label="Enter your ID"
                                            v-model="trackID"
                                       >
                                    </v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="12">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="getUserData" class="mx-2" color="primary" dark small>
                                        <v-icon dark> search</v-icon>
                                        search
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.firstName"
                                          label="First Name "
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.lastName"
                                          label="Last Name"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-radio-group v-model="customerData.gender" row label="Gender">
                                <v-radio label="Male" value="male"></v-radio>
                                <v-radio label="Female" value="female"></v-radio>
                                <v-radio label="Other" value="other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu1"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.dateOfBirth"
                                                  label="Date of Birth"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.nationality"
                                          label="Nationality"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.country"
                                            :items="countries"
                                            label="Country"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            @change="getState"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.state"
                                            :items="state"
                                            label="State"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>

                        <!--<v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="customerData.ageGroup" :items="PassengerTypes"
                                            item-value="value"
                                            item-text="value"
                                            label="Age Group"></v-autocomplete>
                        </v-col>-->

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.citizenShipNumber"
                                          label="Citizenship Number"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.passportNumber"
                                          label="Passport Number"
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.licenseNumber"
                                          label="License Number "
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.otherID"
                                          label="Other Id "
                                          required></v-text-field>
                        </v-col>
                        <!--                        <v-col-->
                        <!--                                cols="12"-->
                        <!--                                sm="6"-->
                        <!--                                md="4"-->
                        <!--                        >-->
                        <!--                            <v-file-input-->
                        <!--                                    v-model="customerData.document"-->
                        <!--                                    show-size-->
                        <!--                                    label="Document"-->
                        <!--                            ></v-file-input>-->
                        <!--                        </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isVerifiedDocument"
                                      label="Is Verified Document?"
                                      clearable></v-switch>
                        </v-col>


                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.IDValidity"
                                                  label="ID Validity"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.IDValidity">

                                </v-date-picker>
                            </v-menu>
                        </v-col>


                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.VisaValidity"
                                                  label="Visa Validity"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.VisaValidity">

                                </v-date-picker>
                            </v-menu>
                        </v-col>


                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.ArrivalDeparture"
                                            :items="['Arrival','Departure','Transit']"
                                            label="Arrival / Departure"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.DateOfArrival"
                                                  label="Date Of Arrival/Departure"
                                                  clearable
                                                  prepend-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.DateOfArrival">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                                    

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.PurposeOfVisit"
                                          label="Purpose Of Visit"
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <!--                            <v-text-field-->
                            <!--                                    v-model="customerData.PortOfEntryExit"-->
                            <!--                                    label="Port Of Entry Exit"-->
                            <!--                                    required-->
                            <!--                            ></v-text-field>-->
                            <v-autocomplete v-model="customerData.PortOfEntryExit"
                                            :items="entryPort"
                                            label="Port Of Entry Exit"
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.AirLineName"
                                          label="AirLineName"
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                           md="4" sm="6">
                            <v-autocomplete :items="FlightTypes" label="Flight Type"
                             v-model="customerData.flightType"></v-autocomplete>
                    </v-col>

                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ImmigartionCustomerForm",
        data() {
            return {
                customerData: {
                    fromAmount: 1,
                    toAmount: 1,
                    unitExchangeRate:1
                },
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu1: false,
                    dateMenu2: false,
                    dateMenu3: false,
                    dateMenu4: false,
                    dateMenu5: false
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                exchangeRates: [],
                rateType: ['Buy', 'Sell'],
                AirPackages: [],
                PassengerTypes: [],
                FlightClasses: [],
                FlightTypes: ['International', 'Domestic'],
                Issuer: ['E-Sewa'],
                personType:["Child","Adult"],
                servicesData:{
                    data:{},
                    message:"",
                    color:""
                },
                toAmount:1,
                entryPort:[],


                citizenshipData:[],
                passportData:[],
                dropdownData:{
                    citizenShipNumber:"",
                    passportNumber:""
                },
                isLoading: false,
                searchCitizen: null,
                searchPassport: null,
            }
        },
        watch:{
            "dropdownData.citizenShipNumber": {
                handler: function (val) {
                    if (val) {
                        axios.get("Shared/GetPersonInformationByCitizenshipAsync",{
                            params:{
                                CitizenShipNumber: val.value
                            }
                        }).then(response=>{
                            this.customerData = response.data
                            if(response.data.country){
                                this.getState(response.data.country)
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                },
                deep: true
            },
            "dropdownData.passportNumber": {
                handler: function (val) {
                    if (val) {
                        axios.get("Shared/GetPersonInformationByPassportAsync",{
                            params:{
                                passportNumber: val.value
                            }
                        }).then(response=>{
                            this.customerData = response.data
                            if(response.data.country){
                                this.getState(response.data.country)
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                },
                deep: true
            },
            searchCitizen(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items

                    axios.get("Track/GetCitizenIDListAsync", {
                        params: {
                            CitizenID: val
                        }
                    })
                        .then(res => {
                            this.citizenshipData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
            searchPassport(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                    axios.get("Track/GetPassportIDListAsync", {
                        params: {
                            PassportNumber: val
                        }
                    })
                        .then(res => {
                            this.passportData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
            "customerData.ExchangeData": {
                handler: function(val) {
                    if(val){

                        let exchange =this.exchangeRates.filter(response=> response.id == val)
                        let curr = exchange[0].id.split('-')
                        this.customerData.fromCurrency = curr[0]
                        this.customerData.toCurrency = curr[1]
                        /*this.customerData.fromAmount = 1*/
                        this.customerData.unitExchangeRate = parseInt(exchange[0].value)
                        if(this.customerData.fromAmount){
                            this.customerData.toAmount = this.customerData.fromAmount*this.customerData.unitExchangeRate
                        }

                    }
                },
                deep: true
            },
            "customerData.fromAmount": {
                handler: function(val){
                    if(val) {
                        this.customerData.toAmount = val*this.customerData.unitExchangeRate
                        if(this.isTypeBuy){
                            axios.get('MoneyExchange/DDLBuyingExchangeRateListAsync/' + val).then(response=>{this.exchangeRates = response.data;}).catch(error=>console.log(error.response))
                        }
                    }
                },
                deep: true
            }
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            getUserData() {
                if (this.trackID) {
                    this.userInfo = []
                    axios.get('Track/GetPersonInformationByID', {
                        params: {
                            ID: this.trackID,
                            Type: this.trackType
                        }
                    }).then(response => {
                        console.log(this.customerData, response.data)
                        this.customerData.firstName = response.data.firstName
                        this.customerData.lastName = response.data.lastName
                        this.customerData.country = response.data.country
                        this.customerData.state = response.data.state
                        this.customerData.gender = response.data.gender
                        this.customerData.age = response.data.age
                        this.customerData.citizenShipNumber = response.data.citizenShipNumber
                        this.customerData.passportNumber = response.data.passportNumber
                        this.customerData.passportNumber = response.data.passportNumber
                        this.customerData.licenseNumber = response.data.licenseNumber
                        if (response.data.country) {
                            this.getState(response.data.country)
                        }
                        this.trackID = ""
                    })
                }
            },

            formSubmit() {
                 var formData = new FormData(); 
                 formData.append("firstName", this.customerData.firstName);
                 formData.append("lastName", this.customerData.lastName);
                 formData.append("gender", this.customerData.gender);
                 formData.append("dateOfBirth", this.customerData.dateOfBirth);
                 formData.append("ageGroup", this.customerData.ageGroup);
                 formData.append("nationality", this.customerData.nationality);
                 formData.append("country", this.customerData.country);
                 formData.append("state", this.customerData.state);
                 formData.append("citizenShipNumber", this.customerData.citizenShipNumber||"");
                 formData.append("passportNumber", this.customerData.passportNumber||"");
                 formData.append("licenseNumber", this.customerData.licenseNumber||"");
                 formData.append("otherID", this.customerData.otherID||"");
                 formData.append("document", this.customerData.document);
                 formData.append("isVerifiedDocument", this.customerData.isVerifiedDocument);
                 formData.append("isImportFromExcel", false);
                 formData.append("fromCurrency", this.customerData.fromCurrency);
                 formData.append("toCurrency", this.customerData.toCurrency);
                 formData.append("customerType", this.customerData.personType);  
                 formData.append("fromAmount", this.customerData.fromAmount);  
                 formData.append("toAmount", this.customerData.toAmount);
                 formData.append("ExchangeRate", this.customerData.unitExchangeRate);
                 formData.append("rateType", this.customerData.rateType);
                 formData.append("issuedDate", this.customerData.issuedDate); 
                formData.append("issuedBy", this.customerData.issuedBy);
                formData.append("age", this.customerData.age); 
                axios.post('Immigration/InsertIntoImmigrationAsyc', this.customerData)
                    .then(response => {
                        if (response.data.success) { 
                            this.editedData = {};
                            this.servicesData.data=response.data
                            this.servicesData.message="Customer added succcessfully"
                            this.servicesData.color="success"
                            this.dialogueClose()
                        }
                    })
                    .catch(response => {
                        console.log(response.data)
                        this.message.status = false
                        this.message.text = 'Error Adding Service, Please Contact Admin'
                    })
            },
            async getState(val){
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode='+val);
                this.state= stateList.data
            },
            formData() {
                axios.get("Shared/GetPortOfEntryList").then(response=>{this.entryPort = response.data;}).catch(error=>console.log(error.response))
                axios.get("Shared/GetAgeGroupList").then(response=>{this.PassengerTypes = response.data;}).catch(error=>console.log(error.response))
                axios.get("Shared/CountryList").then(response=>{this.countries = response.data;}).catch(error=>console.log(error.response))
            },
            callExchangeRate(){
                this.customerData.fromAmount = 1
                if(! this.isTypeBuy){
                    axios.get('MoneyExchange/DDLSellingExchangeRateListAsync').then(response=>{this.exchangeRates = response.data;}).catch(error=>console.log(error.response))
                }
                if(this.isTypeBuy){
                    axios.get('MoneyExchange/DDLBuyingExchangeRateListAsync/' + this.customerData.fromAmount).then(response=>{this.exchangeRates = response.data;}).catch(error=>console.log(error.response))
                }
            }
        },
        mounted() {
            this.formData();
        },
        computed: {
            isTypeBuy() {
                return this.customerData.rateType === "Buy";
            }
        },
    }
</script>

<style scoped>

</style>