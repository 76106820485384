<template>
    <v-content>
        <v-snackbar v-model="snackbar.snackbar"
                    color="success"
                    :timeout="snackbar.timeout"
                    top>
            {{ callbackResponse.message }}
            <v-btn dark
                   text
                   @click="snackbar.snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

        <v-container fluid>
            <v-container>
                <v-row class="float-right">
                    <!--<v-file-input v-model="formdata.import" label="Import File" outlined dense></v-file-input>
                    <v-btn class="primary float-right mx-4" @click.stop="onImport">
                        <v-icon>publish</v-icon>
                    </v-btn>-->
                    
                </v-row>
            </v-container>
            <!--<div slot="table-actions">
                <v-btn color="primary" @click.stop="addModal">Customer</v-btn>
            </div>-->

            <v-expansion-panels hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row align="start" justify="end">

                                <v-col cols="12" md="2">
                                    <v-autocomplete v-model="serverParams.Nationality"
                                                  :items="countries"
                                                    item-text="value"
                                                    dense
                                                    outlined
                                                    item-value="id"
                                                  :label="$t('country_name')"
                                                  clearable></v-autocomplete>

                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-autocomplete :items="arrivaldepart"
                                                    clearable
                                                    item-text="value"
                                                    item-value="id"
                                                    dense
                                                    outlined
                                                    :label="$t('arrival/departure')"
                                                    required
                                                    v-model="serverParams.ArrivalDeparture"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-menu v-model="snackbar.menu1"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            :close-on-content-click="false"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="serverParams.FromDate"
                                                          :label="$t('from_date')"
                                                          clearable
                                                          dense
                                                    outlined
                                                          prepend-inner-icon="event"
                                                          readonly
                                                          v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="serverParams.FromDate">

                                        </v-date-picker>
                                    </v-menu>
                                </v-col>



                                <v-col cols="12" md="2">
                                    <v-menu v-model="snackbar.menu2"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            :close-on-content-click="false"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="serverParams.ToDate"
                                                          :label="$t('to_date')"
                                                          clearable
                                                          dense
                                                    outlined
                                                          prepend-inner-icon="event"
                                                          readonly
                                                          v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="serverParams.ToDate">

                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-autocomplete :items="gender"
                                                    clearable
                                                    item-text="value"
                                                    item-value="id"
                                                    dense
                                                    outlined
                                                    :label="$t('gender')"
                                                    required
                                                    v-model="serverParams.Gender"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="1">
                                    <v-autocomplete :items="ageGroup"
                                                    clearable
                                                    item-text="value"
                                                    item-value="id"
                                                    dense
                                                    outlined
                                                    :label="$t('age_group')"
                                                    required
                                                    v-model="serverParams.AgeGroup"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="2" class="pb-5">
                                    <v-checkbox v-model="serverParams.WithoutNepal"
                                                :label="$t('without_nepal')"></v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="7">
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                                <i class="material-icons">search</i>{{$t('search')}}
                                            </v-btn>
                                            <v-btn color="rgb(157, 25, 25)" small dark @click="clearInput">
                                                <i class="material-icons">clear</i>{{$t('clear')}}
                                            </v-btn>
                                            <!--<v-btn class="success mx-4" small @click.stop="onDownload">
                    <v-icon>get_app</v-icon>
                    Export
                </v-btn>-->
                                            <v-btn @click.stop="onDownload" small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>
                                        </template>
                                        <span>Filter</span>
                                    </v-tooltip>
                                </v-col>

                            </v-row>
                        </v-container>



                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-dialog v-model="dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
            </v-dialog>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            max-height="700px"
                            :fixed-header="true"
                            style="z-index:1"
                            :totalRows="customerData.totalRecords"
                            :pagination-options="paginationOptions"
                            :isLoading.sync="isLoading"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'">
                        <v-icon small class="mr-2" @click.stop="editItem(props.row, 'CustomerForm')">edit</v-icon>
                        <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                    </span>
                </template>
                <template slot="loadingContent">
                    <v-progress-circular :size="50"
                                         color="primary"
                                         indeterminate></v-progress-circular>

                </template>
            </vue-good-table>
            <v-dialog v-model="snackbar.formModal"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      persistent>
                <v-card>
                    <!-- <component :is="snackbar.dynamicComponent.current"
                    @dialogueClose="dialogueClose"
                    @formResponse="formResponse"
                    v-bind="customerData.updateItem"
                    ></component>-->
                    <CustomerForm @dialogueClose="dialogueClose"
                                  @formResponse="formResponse"
                                  v-if="snackbar.createFormComponent"></CustomerForm>
                    <CustomerEditForm @dialogueClose="dialogueClose"
                                      @formResponse="formResponse"
                                      v-if="snackbar.editFormComponent"
                                      :editItemIndex="customerData.editItemIndex">

                    </CustomerEditForm>
                </v-card>
            </v-dialog>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import CustomerForm from "./CustomerForm";
    import CustomerEditForm from "./CustomerEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "HelicopterCustomerList",
        components: {
            'CustomerForm': CustomerForm,
            'CustomerEditForm': CustomerEditForm,
            deleteListData
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                gender:[],
                columns: [
                    {
                        label: 'date',
                        field: 'ad',
                    },
                    {
                        label: 'passport',
                        field: 'passportNumber',
                    },
                    {
                        label: 'full_name',
                        field: this.fullName
                    },
                    {
                        label: 'dob',
                        field: 'dob',
                    },
                    {
                        label: 'sex',
                        field: 'gender',
                    },
                    {
                        label: 'country',
                        field: 'nationality',
                    },
                    {
                        label: 'arrival/departure',
                        field: 'arrivalDeparture',
                    }
                    //,
                    //{
                    //    label: "Actions",
                    //    field: "actions"
                    //}
                ],
                rows: [],
                genderitem: [{ id: "Male", value: "Male", IsNp: false }, { id: "Male", value: "पुरुष", IsNp: true }, { id: "Female", value: "Female", IsNp: false }, { id: "Female", value: "महिला", IsNp: true }],
                arrivaldepart: [{ id: "Arrival", value: "Arrival" }, { id: "Departure", value: "Departure" }],
                dialogDelete: false,
                deleteFormData: [],
                countries: [],
                WithoutNepal: false,
                formdata: [],
                customerData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    Nationality: '',
                    AgeGroup:'',
                    Gender:'',
                    ArrivalDeparture: '',
                    WithoutNepal: false,
                    columnFilters: {},
                    sort: [
                        {
                            field: "AD",
                            type: "DESC"
                        }
                    ],
                    page: 1,
                    perPage: 20
                },
                paginationOptions: {
                    enabled: true,
                    mode: 'records',
                    perPageDropdown: [20, 50, 100, 200],
                    dropdownAllowAll: false,
                    nextLabel: 'next',
                    prevLabel: 'prev',
                    rowsPerPageLabel: 'Rows per page',
                    ofLabel: 'of',
                    pageLabel: 'page', // for 'pages' mode
                    allLabel: 'All',
                },
                isLoading: false,
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    breadcrumbsItem: [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            to: '/reportModules',
                            exact: true

                        },
                        {
                            text: 'Immigration Report',
                            disabled: true,
                        },
                    ],
                    dynamicComponent: {
                        current: 'CustomerForm'
                    }
                }
            };
        },

        methods: {

            customFilter(item, queryText) {
                const textOne = item.name.toLowerCase()
                const textTwo = item.code.toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },
            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("Immigration/Import", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },
            //async onDownload() {
            //    await axios({
            //        url: 'Immigration/Download',
            //        method: 'POST',
            //        responseType: 'blob'
            //    }).then((response) => {
            //        const url = window.URL.createObjectURL(new Blob([response.data]));
            //        const link = document.createElement('a');
            //        link.href = url;
            //        link.setAttribute('download', 'ImmigrationSampleFile.xlsx'); //or any other extension
            //        document.body.appendChild(link);
            //        link.click();
            //    });
            //},

            async onDownload() {

                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                    Nationality: this.serverParams.Nationality,
                    AgeGroup: this.serverParams.AgeGroup,
                    Gender: this.serverParams.Gender,
                    ArrivalDeparture: this.serverParams.ArrivalDeparture,
                    WithoutNepal: this.serverParams.WithoutNepal,
                };


                await axios({
                    url: 'Immigration/Download',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImmigrationSampleFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                });
            },
            fullName(rowObj) {
                return rowObj.fullName
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },

            clearInput() {
                this.serverParams.FromDate = null;
                this.serverParams.ToDate = null;
                this.serverParams.Nationality = null;
                this.serverParams.AgeGroup = null;
                this.serverParams.Gender = null;
                this.serverParams.ArrivalDeparture = null;
                this.serverParams.WithoutNepal = false;
               /* this.loadItems();*/
            },
            dialogueClose() {
                this.customerData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async FirstloadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                    Nationality: this.serverParams.Nationality,
                    AgeGroup: this.serverParams.AgeGroup,
                    Gender: this.serverParams.Gender,
                    ArrivalDeparture: this.serverParams.ArrivalDeparture,
                    WithoutNepal: this.serverParams.WithoutNepal,
                };

                axios.post('Immigration/GetTopCustomerInformationListAsync', param).then(response => {
                    this.rows = response.data.data;
                    console.log('data', this.rows);
                    this.customerData.totalRecords = response.data.totalCount;
                    this.snackbar.loading = false;
                })
                    .catch(err => {
                        console.log(err)
                    });
            },
            async loadItems() {
                if (this.serverParams.Nationality != "" || this.serverParams.FromDate != null || this.serverParams.ToDate != null || this.serverParams.AgeGroup != "" || this.serverParams.Gender != "" || this.serverParams.ArrivalDeparture != "" || this.serverParams.WithoutNepal == true) {
                    this.snackbar.isLoading = true;
                    let param = {
                        PageSize: this.serverParams.perPage,
                        PageNo: this.serverParams.page,
                        OrderType: this.serverParams.sort[0].type,
                        OrderBy: this.serverParams.sort[0].field,
                        FromDate: this.serverParams.FromDate,
                        ToDate: this.serverParams.ToDate,
                        Nationality: this.serverParams.Nationality,
                        AgeGroup: this.serverParams.AgeGroup,
                        Gender: this.serverParams.Gender,
                        ArrivalDeparture: this.serverParams.ArrivalDeparture,
                        WithoutNepal: this.serverParams.WithoutNepal,

                    };
                    this.isLoading = true
                    axios.post('Immigration/GetCustomerInformationListAsync', param).then(response => {
                        this.rows = response.data.data;
                        console.log('data', this.rows);
                        this.customerData.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                        this.isLoading = false
                    })
                        .catch(err => {
                            this.isLoading = false

                            console.log(err)
                        });
                }
                if (this.serverParams.Nationality == "" && this.serverParams.FromDate == null && this.serverParams.ToDate == null && this.serverParams.AgeGroup == "" && this.serverParams.Gender == "" && this.serverParams.ArrivalDeparture == "" && this.serverParams.WithoutNepal == false) {
                    this.snackbar.isLoading = true;
                    let param = {
                        PageSize: this.serverParams.perPage,
                        PageNo: this.serverParams.page,
                        OrderType: this.serverParams.sort[0].type,
                        OrderBy: this.serverParams.sort[0].field,
                        FromDate: this.serverParams.FromDate,
                        ToDate: this.serverParams.ToDate,
                        Nationality: this.serverParams.Nationality,
                        AgeGroup: this.serverParams.AgeGroup,
                        Gender: this.serverParams.Gender,
                        ArrivalDeparture: this.serverParams.ArrivalDeparture,
                        WithoutNepal: this.serverParams.WithoutNepal,
                    };

                    axios.post('Immigration/GetTopCustomerInformationListAsync', param).then(response => {
                        this.rows = response.data.data;
                        console.log('data', this.rows);
                        this.customerData.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                        .catch(err => {
                            console.log(err)
                        });
                }
                
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                if (this.serverParams.Nationality != "" || this.serverParams.FromDate != null || this.serverParams.ToDate != null || this.serverParams.AgeGroup != "" || this.serverParams.Gender != "" || this.serverParams.ArrivalDeparture != "" || this.serverParams.WithoutNepal == true) {
                    this.loadItems();
                }
                if (this.serverParams.Nationality == "" && this.serverParams.FromDate == null && this.serverParams.ToDate == null && this.serverParams.AgeGroup == "" && this.serverParams.Gender == "" && this.serverParams.ArrivalDeparture == "" && this.serverParams.WithoutNepal == false) {
                    this.FirstloadItems();
                }
                
            },
            async formData() {
                axios.get("Shared/GetAgeGroupList").then(res => {
                    this.ageGroup = res.data;
                }).catch(err => {
                    throw err
                })
                const countryList = await axios.get("Shared/CountryList");
                this.countries = countryList.data;
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                if (this.serverParams.Nationality != "" || this.serverParams.FromDate != null || this.serverParams.ToDate != null || this.serverParams.AgeGroup != "" || this.serverParams.Gender != "" || this.serverParams.ArrivalDeparture != "" || this.serverParams.WithoutNepal == true) {
                    this.loadItems();
                }
                if (this.serverParams.Nationality == "" && this.serverParams.FromDate == null && this.serverParams.ToDate == null && this.serverParams.AgeGroup == "" && this.serverParams.Gender == "" && this.serverParams.ArrivalDeparture == "" && this.serverParams.WithoutNepal == false) {
                    this.FirstloadItems();
                }
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                if (this.serverParams.Nationality != "" || this.serverParams.FromDate != null || this.serverParams.ToDate != null || this.serverParams.AgeGroup != "" || this.serverParams.Gender != "" || this.serverParams.ArrivalDeparture != "" || this.serverParams.WithoutNepal == true) {
                    this.loadItems();
                }
                if (this.serverParams.Nationality == "" && this.serverParams.FromDate == null && this.serverParams.ToDate == null && this.serverParams.AgeGroup == "" && this.serverParams.Gender == "" && this.serverParams.ArrivalDeparture == "" && this.serverParams.WithoutNepal == false) {
                    this.FirstloadItems();
                }
            },

            onColumnFilter(params) {
                this.updateParams(params);
                if (this.serverParams.Nationality != "" || this.serverParams.FromDate != null || this.serverParams.ToDate != null || this.serverParams.AgeGroup != "" || this.serverParams.Gender != "" || this.serverParams.ArrivalDeparture != "" || this.serverParams.WithoutNepal == true) {
                    this.loadItems();
                }
                if (this.serverParams.Nationality == "" && this.serverParams.FromDate == null && this.serverParams.ToDate == null && this.serverParams.AgeGroup == "" && this.serverParams.Gender == "" && this.serverParams.ArrivalDeparture == "" && this.serverParams.WithoutNepal == false) {
                    this.FirstloadItems();
                }
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.customerData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Immigration/DeleteCustomerInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            let questionNepali = localStorage.getItem('lang');
            this.gender = [];
            if (questionNepali == 'ne') {

                this.gender = this.genderitem.filter(function (data) {
                    return data.IsNp === true;
                });
            }
            else {
                this.gender = this.genderitem.filter(function (data) {
                    return data.IsNp === false;
                });
            }
            this.FirstloadItems();
            this.formData();
            //this.loadItems()
        }
    }
</script>

<style lang="scss">
    .vgt-wrap__footer {
        color: white;
        padding: 1em;
        border: 1px solid #dcdfe6;
    }

    /*   ::v-deep.vgt-wrap__footer {
        ::v-deep.footer__navigation {
            ::v-deep .footer__navigation__page-btn {
                color: #eee !important;

            }
        }
    } */

    .vgt-wrap__footer .footer__navigation__page-btn {
        text-decoration: none;
        color: #ffffff;
        font-weight: 700;
        white-space: nowrap;
    }
</style>